<template>
  <div class="home">
    <section id="form-container" v-if="!loading">
      <h2>Please Sign In</h2>
      <form @submit.prevent="handleSubmit">
        <label class="email" for="email">
          <span>Email</span>
          <input id="email" type="text" v-model="email" required />
          <p v-if="errors.email" class="error">{{ errors.email }}</p>
        </label>
        <label class="password" for="password">
          <span>Password</span>
          <input id="password" type="password" v-model="password" required />
          <p v-if="errors.password" class="error">{{ errors.password }}</p>
        </label>
        <button>Sign In</button>
      </form>

      <p v-show="loginError" class="login-error">Invalid Credentials</p>

      <div class="request-auto-login">
        <span>Want to login without a password? </span>
        <router-link to="/request-auto-login"
          >Request an automatic sign-in link</router-link
        >
      </div>

      <div class="warning">
        <p>
          IMPORTANT: This website is intended for Founder Sport Rep use only.
        </p>
        <p>Dealers, please contact your Rep for assistance using Gameplan.</p>
      </div>
    </section>

    <section id="form-container" v-else>
      <Loader />
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../components/Utils/Loader.vue";
export default {
  components: { Loader },
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      loading: false,
      loginError: false,
      errors: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapState(["endpoint"]),
    formHasErrors() {
      return this.errors.email.length > 0 || this.errors.password.length > 0;
    },
  },

  methods: {
    ...mapActions(["requestLogin"]),

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    checkForm() {
      this.loginError = false;
      //Reset errors
      this.errors = {
        email: "",
        password: "",
      };

      const validEmail = this.validateEmail(this.email);
      const validPassword = this.password.length > 2;

      if (!validEmail) {
        this.errors["email"] = "Enter a valid email";
      }
      if (!validPassword) {
        this.errors["password"] = "Enter a valid password";
      }
    },

    async handleSubmit() {
      this.checkForm();

      if (!this.formHasErrors) {
        try {
          this.loading = true;
          await this.requestLogin({
            email: this.email,
            password: this.password,
          });

          this.loading = false;
        } catch (e) {
          this.loginError = true;
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  background: url("/images/homepage-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 70px);

  #form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    height: 40%;
    min-height: 550px;
    max-width: 720px;
    padding: 2% 7%;
    background-color: white;
    margin: auto;

    & > * {
      margin-bottom: 2rem;
    }

    h2 {
      text-align: center;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 0;
        &.email {
          width: 100%;
          margin-bottom: 1.5rem;
        }
        &.password {
          width: 65%;
        }

        input {
          padding: 1rem;
          border-radius: 5px;
          border: 1px solid black;
        }

        p.error {
          margin: 0;
          background: rgb(252, 61, 61);
          color: whitesmoke;
          border-radius: 0 0 5px 6px;
          padding: 0.2rem;
          font-weight: bold;
        }
      }

      button {
        width: 30%;
        background-color: black;
        height: 47px;
        color: white;
        border: none;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .login-error {
      width: 100%;
      text-align: center;
      padding: 1rem;
      background: rgb(252, 61, 61);
      color: white;
    }

    .request-auto-login {
      text-align: center;
      span {
        margin-right: 0.2rem;
      }
      a {
        text-decoration: underline;
        font-weight: bold;
      }
    }

    .warning {
      p {
        text-align: center;
        font-size: 1.3rem;
        margin-bottom: 0.1rem;
      }
    }

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  @media (max-width: 1024px) {
    height: calc(100vh - 50px);
  }
}
</style>